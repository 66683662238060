<template>
  <div>
    <router-link to="/">
      <img class="logo" src="@/assets/medsafe-logo.png"  alt=""/>
    </router-link>
  </div>
</template>

<style scoped>
    .logo {
      height: 52px;
      width: 132px;
    }
</style>
