import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3449bea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable-cell__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style),
    class: "expandable-cell"
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 4))
}