
import { PropType } from 'vue';

export default {
  name: 'PinIconButton',
  props: {
    crossedOut: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
};
