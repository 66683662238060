import { TableStructure } from '~components/TableComponent/types';

export const defaultStructure: TableStructure = {
  trusted_struct: true,
  title: 'Default Table',
  field_id: '',
  columns: [
    {
      name_column: '',
      colname: '',
      visible: false,
      content_type: 'default',
    },
  ],
};
