import { defineAsyncComponent } from 'vue';
import { TableRow, TableColumn } from './types';
import SimpleCell from './SimpleCell.vue';
import EditableCell from './EditableCell.vue';
import ExpandableCell from './ExpandableCell.vue';
import CustomButton from './CustomButton.vue';
import JsonComponent from './JsonComponent.vue';

const cellsTypeMap = {
  expandable: ExpandableCell,
  json_string: JsonComponent,
  json_array: JsonComponent,
  custom: null,
  button: CustomButton,
  editable: EditableCell,
  default: SimpleCell,
};

export const defineComponentName = (column: TableColumn) => {
  if (column.content_type === 'custom' && column.custom_component_name) {
    return defineAsyncComponent(() => import(`@/components/TableComponent/${column.custom_component_name}.vue`));
  }
  if (!column.content_type || !cellsTypeMap[column.content_type]) {
    return cellsTypeMap.default;
  }
  return cellsTypeMap[column.content_type];
};

const transformDataByDatatype = (text: string, column: TableColumn) => {
  switch (column.data_type) {
    case 'money':
      return {
        text: parseFloat(text).toLocaleString('ru-RU', {
          useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2,
        }),
        align: 'right',
      };
    default:
      return { text };
  }
};

export const defineCellProps = (column: TableColumn, content: keyof TableRow) => {
  let transformed;
  if (typeof content === 'number') {
    transformed = { text: content.toString() };
  } else {
    transformed = { text: content };
  }
  if (column.data_type) {
    transformed = transformDataByDatatype(transformed.text, column);
  }
  return {
    ...column,
    ...transformed,
  };
};

export const findEventTargetParent = (event: DragEvent, el: string) => {
  const target = event.target as HTMLElement;
  return target.closest(el);
};
