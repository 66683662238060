import { RouteRecordRaw } from 'vue-router';
import ContractsView from '~views/ContractsView.vue';
import ContactsView from '~views/ContactsView.vue';
import OrgsView from '~views/OrgsView.vue';
import StructureView from '~views/StructureView.vue';
import ActsView from '~views/ActsView.vue';
import PageNotFound from '~views/PageNotFound.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Contracts',
    component: ContractsView,
  },
  {
    path: '/structure',
    name: 'Structure',
    component: StructureView,
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsView,
  },
  {
    path: '/orgs',
    name: 'Orgs',
    component: OrgsView,
  },
  {
    path: '/acts',
    name: 'Acts',
    component: ActsView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
  },
];

export default routes;
