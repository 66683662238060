import script from "./ActsView.vue?vue&type=script&setup=true&lang=ts"
export * from "./ActsView.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSplitter,QSelect,QItem,QItemSection,QItemLabel,QPageSticky,QFab,QFabAction,QTooltip,QBtn,QMenu,QInput});
