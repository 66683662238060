import script from "./ContactsView.vue?vue&type=script&setup=true&lang=ts"
export * from "./ContactsView.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSplitter,QPageSticky,QFab,QFabAction,QTooltip});
