import exceljs from 'exceljs';

type Cell = Record<string, exceljs.CellValue>;

export async function createJsonFromExcel(file: Blob): Promise<string> {
  const cellValueToDict2 = (row: exceljs.Row) => {
    const data: Cell = {};
    row.eachCell((cell, colNumber) => {
      let { value } = cell;
      if (value && typeof value === 'object' && 'text' in value) {
        value = value.text;
      }
      data[colNumber] = value;
    });
    return data;
  };
  const changeRowsToDict = (worksheet: exceljs.Worksheet) => {
    const dataArray: Cell[] = [];
    worksheet.eachRow((row, rowNumber) => {
      let keys = [];
      if (rowNumber === 1) {
        keys = [...Array(row.values.length).keys()];
      } else {
        const rowDict = cellValueToDict2(row);
        dataArray.push(rowDict);
      }
    });
    return dataArray;
  };
  return new Promise((resolve, reject) => {
    const workbook = new exceljs.Workbook();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      if (e.target) {
        const buffer = e.target.result as ArrayBuffer;
        workbook.xlsx.load(buffer)
          .then(async (wb) => {
            const worksheet = wb.getWorksheet(1);
            const dataArray = changeRowsToDict(worksheet);
            dataArray.shift();
            resolve(JSON.stringify(dataArray));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
  });
}
