import script from "./TableComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableComponent.vue?vue&type=script&setup=true&lang=ts"

import "./TableComponent.vue?vue&type=style&index=0&id=d0477ec0&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip,QInput,QMarkupTable,QTr,QCheckbox,QIcon,QMenu,QTd,QSelect,QPagination,QTable});
