import { useOrgsStore } from '~store/orgs/orgsModule';
import { Modules } from '@/enums';
import { defineStore } from 'pinia';
import Api, { Method } from '~utils/Api';
import { ITable, TableRow, TableStructure } from '@/components/TableComponent/types';
import { actionHandler } from '@/actions/actionHandler';
import { Ref } from 'vue';
import { MESSAGE_SHOW_DURATION } from '@/constants';
import { IResponse } from '@/types';
import { ConstantParams, DatesRange, IMessage } from './types';

export const useMainStore = defineStore('main', {
  state: () => ({
    constantParams: {
      id_login: 0,
      TK: '',
      IMEI: '',
      Name_app: '',
      Name_module: Modules.WEBMANAGERS,
    } as ConstantParams,
    modal: false,
    maximized: false,
    modalComponent: {
      name: '',
      params: {},
    },
    messages: [] as IMessage[],
    dates: {
      startDate: '',
      endDate: '',
    } as DatesRange,
    activeTabId: 1,
    resolve: null as unknown as (value: Ref<string>) => void | null,
  }),
  getters: {
    getConstantParams: (state) => state.constantParams,
  },
  actions: {
    setConstantParams(params: ConstantParams) {
      this.constantParams = params;
    },
    openModal(componentName: string, params: any) {
      if (this.modal) {
        this.closeModal();
      }
      setTimeout(() => {
        this.modal = true;
        this.modalComponent.name = componentName;
        this.modalComponent.params = params;
        this.setModalSize(Boolean(params?.maximized));
      }, 0);
    },
    setModalSize(maximized: boolean) {
      this.maximized = maximized;
    },
    closeModal() {
      this.modal = false;
      this.modalComponent = {
        name: '',
        params: {},
      };
    },
    showMessage(content: string) {
      const newMessage = {
        id: Date.now(),
        content: content || 'Неизвестная ошибка',
      };
      this.messages = [
        ...this.messages,
        newMessage,
      ];
      setTimeout(() => {
        this.hideMessage(newMessage.id);
      }, MESSAGE_SHOW_DURATION);
    },
    hideMessage(id: number) {
      const messageToHideIndex = this.messages.findIndex((message) => message.id === id);
      this.messages.splice(messageToHideIndex, 1);
    },
    setCurrentStructureTab(tabNumber: number) {
      this.activeTabId = tabNumber;
    },
    async dispatchEvent(eventName: string, params: any = null):
      Promise<ITable> {
      const Name_event = eventName.split('.')[0];
      const method = Name_event.split(':')[0] as Method;
      const Name_module = eventName.split('.')[1] || this.constantParams.Name_module;
      const { currentOrgId } = useOrgsStore();

      const requestData = {
        ...params,
        ...this.constantParams,
        Name_event,
        Name_module,
        id_org: currentOrgId || 0,
      };

      const result = await Api.all(method, requestData);
      const data = result.data as unknown as Record<string, unknown>[];
      const structure = result.structure as unknown as TableStructure;
      if (params && params.nextAction) {
        await actionHandler(params.nextAction);
      }
      return {
        data,
        structure,
      };
    },
    setDatesRange(dates: DatesRange) {
      this.dates = dates;
    },
    sendReport(error: string | Error) {
      const params = {
        type_report: 1,
        text_report: error,
      };
      this.dispatchEvent('POST:report.module_reports', params);
    },
  },
});
