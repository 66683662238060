/* eslint-disable */

/*
* типы ячеек
* default - просто текст
* expandable - может расширяться при наведении
* editable - можно отредактировать и сохранить конкретное значение
* button - ячейка с кнопкой
* custom - по сути, это возможность для фронта вклиниться и вставить произвольный компонент
* json - тут понятно
* по поводу типа 'checkbox' - да, это обсуждалось, но на практике мы пока что используем чекбоксы только
* в первом столбце для выделения строк. А как собирать данные с произвольных чекбоксов, не очень понятно.
* Поэтому из типов убрал, чекбоксы в первой колонке задаюся свойством TableStructure.selection
*/
export type TableFieldType = 'default' | 'expandable' | 'editable' | 'button' | 'custom' | 'json_string' | 'json_array';

export type DataType = 'money' | 'date' | 'int';

// export type SelectionType = 'single' | 'multiple';

export enum SelectionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum FilterType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export type ActionType = 'event' | 'custom' | 'modal' | 'menu' | 'callback';

export type AlignType = 'left' | 'right' | 'center';

export type StyleRow = {
  [key: string]: string,
}

/*
* filter - если поле должно также выполнять фильтрацию по таблице, указывается name_column 
*/
export interface ISearchSelect {
  id: string,
  field: string,
  label: string,
  options: Array<Record<string, unknown>> | Array<string>,
  action: Action,
  filter: boolean,
  search_from_backend?: ISearchFromBackend,
}

// export interface ISelection {
//   type: SelectionType,
//   selection_callback?: Action,
// }

/*
* name - уникальное название
* type - если надо дернуть ивент на АПИ, то 'event' (и тогда name - это название ивента), по дефолту пока что custom, modal - для открытия модалки
* customParam - любые параметры, с которыми мы хотим выполнить экшен, в джейсоне
*/
export interface Action {
  name: string,
  type: ActionType,
  customParam?: string | Record<string, unknown>,
  confirmation?: boolean,
  confirmationText?: string,
  callback?: Function;
}

export interface DragAndDropHandler extends Action {
  customParam: {
    source_key: string,
    recipient_key: string,
  }
}

/*
* параметры поиска на бэкенде для выпадалки с поиском. Event - имя ивента для поиска, min_length - длина запроса, от которой начинаем искать
*/

export interface ISearchFromBackend {
  action: Action,
  min_length: number,
}

/*
* если поле - не просто текст, то можно указать опциональные свойства:
* icon - название иконки (https://fonts.google.com/icons)
* color - цвет (hex)
* text - надпись
* tooltip: контент тултипа
* field_to_save: в случае editable cell имя поля, которое нужно сохранять
* action - экшен по клику (см Action)
*/
export type FieldContent = {
  icon?: string,
  color?: string,
  text?: string,
  tooltip?: string,
  field_to_save?: string,
  action: Action,
}

/*
* добавил экшен т.к. часто при раздвигании строки надо выполнять выбор / подгрузку / и т.п.
*/
export type ExpandableContent = {
  type: 'button' | 'component',
  value: Button | string,
  action?: Action,
}

/*
* данные о столбце таблицы
* name_column - идентификатор, уникален и совпадает с ключом того поля, которое здесь надо выводить
* colname - русское название для вывода на экран
* visible - если false то не показываем
* sortable - будет ли столбец сортируемым
* pin - можно закрепить столбец от автоскрытия
* hidden - чисто фронтовый параметр, его не надо присылать
* filterable - нужен ли фильтр по столбцу, 'single' - по одному значению, 'multiple' - по нескольким
* width - можно задать дефолтную ширину в пикселях
* content_type - тип поля (см TableFieldType)
* custom_component_name - если тип поля === 'custom' или 'json' здесь можно указать название компонента, который будет выводиться в поле
* value - контент для сложных (не только текст) ячеек (см FieldContent)
* sorted - это чисто фронтовый ключ, его не надо присылать
* tooltip - подсказка для ячейки заголовка
*/
export interface TableColumn {
  name_column: string,
  colname: string,
  description?: string,
  align?: AlignType,
  visible: boolean,
  sortable?: boolean,
  pinned?: boolean,
  column_hidden?: boolean,
  filterable?: FilterType,
  width?: number,
  content_type: TableFieldType,
  data_type?: DataType,
  custom_component_name?: string,
  value?: FieldContent,
  sorted?: string | undefined,
  tooltip?: string,
  renderedWidth?: number,
  alias?: string,
  columnId?: number,
}

export interface TableRow {
  [key: string]: any,
}

/*
* Это общие данные о таблице.
* title: заголовок
* expandable_rows: будут ли строки раздвигаться
* pagination: нужна ли пагинация, если нет нужна то 0
* field_id: указатель на то, какое поле является уникальным ID записи (потому что они могут называться id_service, id_... и т.п.)
* selection: нужен ли выбор строк чекбоксами, 'single' - можно будет выбрать только 1 строку, 'multiple' - несколько строк
* columns: массив с описанием столбцов
* expandable_contents: если хотим прокинуть в раздвигающуюся область что-то кастомное, причем это массив (см. expandableContent)
* export - добавляет в хедер таблицы кнопку "Экпортировать в эксель"
* search - добавляет в хедер таблицы поисковую (фильтрующую) строку
* empty_state - строка, которая выводится если пришла пустая таблица (без данных)
* searchselect - описание поведения селекта в хедере таблицы, включая возможность подтягивания опций с бэка
* name_event: ивент, который возвращает таблицу, служит для идентификации таблицы чтобы сохранять данные в LS
*/
export interface TableStructure {
  name_event?: string,
  trusted_struct?: boolean,
  title?: string,
  field_id: string,
  pagination?: boolean,
  selection?: SelectionType,
  expandable_rows?: boolean,
  search?: string | string[],
  searchselect?: ISearchSelect,
  expandable_contents?: ExpandableContent[],
  export?: boolean,
  empty_state?: string,
  columns: TableColumn[],
  draggable?: DragAndDropHandler,
}

export type ActionParams = {
  [key: string]: string | number | boolean
}

export type Filter = {
  colName: string,
  value: string[] | null,
  type: FilterType
}

/*
* здесь некая задумка на будущее - чтобы с бэка можно было прислать любую кнопь
*/
export type Button = {
  label?: string,
  type?: string,
  icon?: string,
  disable?: boolean,
  tooltip?: string,
  action: Action,
  context?: Record<string, unknown>,
}

/*
* интерфейс описывает совокупность данных и структуры для универсальной таблицы
*/
export interface ITable<T = TableRow> {
  data: T[],
  structure: TableStructure,
}