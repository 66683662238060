export const convertDataForSorting = (str: string) => {
  const parsed = parseInt(str, 10);
  if (Number.isNaN(parsed)) {
    return str;
  }
  return parsed;
};

export const arrayUnique = (array: Array<any>, field: string | null = null) => {
  if (field) {
    return array.filter((n, i, a) => n === a.find((m) => m[field] === n[field]));
  }
  return Array.from(new Set(array));
};

export const trimName = (name: string) => {
  if (name) {
    const nameArr = name.split(' ');
    return `${nameArr[0]} ${nameArr[1].trim().slice(0, 1)}.${nameArr[2].trim().slice(0, 1)}.`;
  }
  return null;
};
