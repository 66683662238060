import { createRouter, createWebHistory } from 'vue-router';
import { useMainStore } from '@/store';
import { useOrgsStore } from '@/store/orgs/orgsModule';
import { useAuthStore } from '@/store/auth/authModule';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const isAuth = useAuthStore().getIsAuth;
  if (!isAuth) {
    await useAuthStore().autenticate();
  }
  if (to.name === 'Orgs') {
    return;
  }
  const isOrgSet = useOrgsStore().currentOrgId;
  if (!isOrgSet) {
    useMainStore().showMessage('Для работы необходимо выбрать организацию!');
    // eslint-disable-next-line consistent-return
    return { name: 'Orgs' };
  }
});

export default router;
