import Node from './Node';
import Queue from './Queue';
import { TreeItem } from './types';

type TreeCallback = (node: Node) => void;

// eslint-disable-next-line no-use-before-define
type Traversal = Tree['traverseBF'] | Tree['traverseDF'];

export default class Tree {
  _root: Node;

  id = 0;

  currentParent: null | Node = null;

  constructor(data: TreeItem) {
    this._root = new Node(data);
  }

  traverseDF(callback: TreeCallback) {
    (function recurse(currentNode: Node) {
      for (let i = 0, { length } = currentNode.children; i < length; i += 1) {
        recurse(currentNode.children[i]);
      }

      callback(currentNode);
    }(this._root));
  }

  traverseBF(callback: TreeCallback) {
    const queue = new Queue();

    queue.enqueue(this._root);

    let currentNode = queue.dequeue();

    while (currentNode) {
      for (let i = 0, { length } = currentNode.children; i < length; i += 1) {
        queue.enqueue(currentNode.children[i]);
      }

      callback(currentNode);
      currentNode = queue.dequeue();
    }
  }

  contains(callback: TreeCallback, traversal: Traversal) {
    traversal.call(this, callback);
  }

  add(data: TreeItem, id_parent: string, traversal: Traversal, index: number | null = null) {
    const child = new Node(data);
    const callback = (node: Node) => {
      if (node?.data?.id_tree === id_parent) {
        this.currentParent = node;
      }
    };

    this.contains(callback, traversal);

    if (this.currentParent) {
      if (index === null) {
        this.currentParent.children.push(child);
        this.currentParent.children.sort((a: Node, b: Node) => {
          let result = 0;
          if (a?.data?.pos_int > b?.data?.pos_int) {
            result = 1;
          } else if (a?.data?.pos_int < b?.data?.pos_int) {
            result = -1;
          }
          return result;
        });
      } else {
        this.currentParent.children.splice(index, 0, child);
      }

      child.parent = this.currentParent;
      return data.id_tree;
    }
    return false;
  }

  search(id: string, id_parent: string, traversal: Traversal) {
    let foundChild = null;

    const callback = (node: Node) => {
      if (node?.data?.id_tree === id_parent) {
        this.currentParent = node;
      }
    };

    this.contains(callback, traversal);

    if (this.currentParent) {
      const index = this.currentParent.children.findIndex((item: Node) => item.data.id_tree === id);
      if (index === -1) {
        throw new Error('Node does not exist.');
      } else {
        foundChild = this.currentParent.children[index];
      }
    }
    return foundChild;
  }

  remove(id: string, id_parent: string, traversal: Traversal) {
    let childToRemove = null;
    let index;

    const callback = (node: Node) => {
      if (node?.data?.id_tree === id_parent) {
        this.currentParent = node;
      }
    };

    this.contains(callback, traversal);

    if (this.currentParent) {
      index = this.currentParent.children.findIndex((item: Node) => item?.data?.id_tree === id);
      if (index === -1) {
        throw new Error('Node to remove does not exist.');
      } else {
        childToRemove = this.currentParent.children.splice(index, 1);
      }
    } else {
      throw new Error('Parent does not exist.');
    }

    return childToRemove;
  }
}
