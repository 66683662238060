import { useMainStore } from '@/store';
import Api, { BASE_URL } from '~utils/Api';
import { Action } from '@/components/TableComponent/types';
import { storeToRefs } from 'pinia';
import { actionHandler } from './actions/actionHandler';
import { createJsonFromExcel } from './utils/exportJsonToExcel';
import { useContractsStore } from './store/contracts/contractsModule';

export interface IDownloadParams {
  document: {
    id_doc?: number,
    id_document?: number,
    doc_name: string,
  },
  type: 'download' | 'open'
}

interface IDocumentPath {
  extension: string,
  hash: string,
}

type DatesRange = {
  id: number,
  label: string,
  startDate: Date,
  endDate: Date,
};

export const uploadFile = async (param: Record<string, unknown>) => {
  let txt = '';
  if (param.file) {
    txt = await createJsonFromExcel(param.file as Blob);
  }

  const { resolve } = storeToRefs(useMainStore());
  const { currentContractId } = storeToRefs(useContractsStore());
  const openModal: Action = {
    name: 'InputFileNameModal',
    type: 'modal',
  };
  actionHandler(openModal);

  const waitConfirm = () => new Promise((res, rej) => {
    resolve.value = res;
  });
  waitConfirm().then((res) => {
    console.log('res');
    const params = {
      txt,
      param: parseInt(param.type as string, 10),
      comment: res,
      id_contract: currentContractId.value,
    };
    const action: Action = {
      type: 'event',
      name: 'POST:employee.module_calculation',
      customParam: {
        ...params,
        nextAction: {
          name: 'loadEmployeeLists',
          type: 'custom',
        },
      },
    };
    actionHandler(action);
    useMainStore().closeModal();
  });
};

export const exportToExcel = () => {

};

export const getFilePath = async (id: number) => {
  const mainStore = useMainStore();
  const result = await mainStore.dispatchEvent('get_pic_path.WEB_division', { id_doc: id });
  if (result) {
    const path = result.data as IDocumentPath[];
    const { extension, hash } = path[0];
    return { extension, hash };
  }
  return false;
};

export const openInBrowser = async (id: number) => {
  const filePath = await getFilePath(id);
  if (filePath) {
    const { hash } = filePath;
    window.open(`${BASE_URL}${hash}`, '_blank');
  }
};

export const downloadDocument = async (params: IDownloadParams) => {
  const mainStore = useMainStore();
  const id = params.document.id_doc || params.document.id_document || null;
  if (id === null) {
    return false;
  }
  const filePath = await getFilePath(id);
  if (filePath) {
    const { extension, hash } = filePath;
    const file = await Api.getFile(hash);
    if (file && typeof file !== 'boolean') {
      const link = document.createElement('a');
      if (params.type === 'download') {
        const url = window.URL.createObjectURL(new Blob([file]));
        link.href = url;
        link.setAttribute('download', `${params.document.doc_name}.${extension}`);
      } else {
        link.href = BASE_URL + hash;
        link.target = 'blank';
      }

      document.body.append(link);
      link.click();
      link.remove();
    } else {
      mainStore.showMessage('Файл не найден!');
    }
  }
  return false;
};

export const deleteDocument = async (id: number) => {
  const result = await useMainStore().dispatchEvent('del_doc.module_calculation', { id_doc: id });
  if (typeof result !== 'boolean') {
    return true;
  }
  return false;
};

export const extractData = (data: string | null | any) => {
  if (!data || typeof data !== 'string') {
    return null;
  }
  try {
    const toReturn = JSON.parse(data);
    return toReturn;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const downloadFile = (url: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'download');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const currentDate = new Date();

export const presets: DatesRange[] = [
  {
    id: 0,
    label: 'Текущий месяц',
    startDate: currentDate.getDate() > 5
      ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      : new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    endDate: currentDate,
  },
  {
    id: 1,
    label: 'Прошлый месяц',
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
  },
  {
    id: 2,
    label: 'Текущий год',
    startDate: new Date(currentDate.getFullYear(), 0, 1),
    endDate: currentDate,
  },
  {
    id: 3,
    label: 'Все даты',
    startDate: new Date('1970-01-01'),
    endDate: currentDate,
  },
];
