import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import { createPinia } from 'pinia';
import TableComponent from '~components/TableComponent/TableComponent.vue';
// import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import quasarUserOptions from './quasar-user-options';

const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: 'https://475a07e15fb24481a6b11acfbfc72e8e@o4505143269785600.ingest.sentry.io/4505144719114240',
//   logErrors: true,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
// });

app.use(createPinia()).use(Quasar, quasarUserOptions).use(router).component('TableComponent', TableComponent)
  .mount('#app');
