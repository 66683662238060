import script from "./DatePickerAnchorButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./DatePickerAnchorButton.vue?vue&type=script&setup=true&lang=ts"

import "./DatePickerAnchorButton.vue?vue&type=style&index=0&id=7a7a35a4&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
