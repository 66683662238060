import { ITable } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import { Modules } from '@/enums';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import { useOrgsStore } from '../orgs/orgsModule';
import {
  IResponsible, IContact, IAccount, ILoginGroup,
} from './types';

export const useResponsiblingsStore = defineStore('responsiblings', {
  state: () => ({
    constantParams: useMainStore().constantParams,
    fullResponsiblingsList: {} as ITable<IResponsible>,
    selectedContact: null as null | IContact,
    loginsGroups: {} as ITable<ILoginGroup>,
  }),
  getters: {
    responsiblingsList: (state) => state.fullResponsiblingsList,
    fullContactsList: (state) => state.fullResponsiblingsList.data
      .filter((item) => item.contacts && item.contacts.length)
      .map((item) => {
        if (typeof item.contacts === 'string') {
          try {
            const contacts = JSON.parse(item.contacts);
            return contacts;
          } catch (error) {
            console.log(error);
          }
        }
        return item.contacts;
      })
      .flat() as IContact[],
  },
  actions: {
    async loadResponsiblings() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:responsiblings_json',
        Name_module: Modules.CALCULATION,
        id_org: useOrgsStore().currentOrgId,
      };
      const result = await Api.get(params);
      if (result.data && result.structure) {
        this.fullResponsiblingsList = {
          data: result.data as IResponsible[],
          structure: result.structure,
        };
      }
    },
    selectContact(val: null | IContact) {
      this.selectedContact = val;
    },
    async loadLoginsGroups() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:logins_groups',
        id_org: useOrgsStore().currentOrgId,
      };
      const result = await Api.get(params);
      if (result.data && result.structure) {
        this.loginsGroups = {
          data: result.data as ILoginGroup[],
          structure: result.structure,
        };
      }
    },
  },
});
