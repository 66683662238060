import { ITable } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import { useAuthStore } from '~store/auth/authModule';
import { useTreeStore } from '~store/tree/treeModule';
import { useContractsStore } from '~store/contracts/contractsModule';
import { useResponsiblingsStore } from '~store/responsiblings/responsiblingsModule';
import { usePatientsStore } from '../patients/patientsModule';
import {
  Org, Doer, ContractType, ExistedOrg,
} from './types';

export const useOrgsStore = defineStore('orgs', {
  state: () => ({
    fullOrgList: {} as ITable<Org>,
    constantParams: useMainStore().constantParams,
    currentOrg: {} as Org,
    doers: [] as Doer[],
    contractTypes: [] as ContractType[],
    existedOrg: {} as ExistedOrg,
  }),
  getters: {
    currentOrgId: (state) => state.currentOrg.id_org,
    getConstantParams() {
      const mainStore = useMainStore();
      return mainStore.constantParams;
    },
  },
  actions: {
    async getFullOrgList() {
      const params = {
        Name_event: 'GET:orgs_list_full_2',
        ...this.getConstantParams,
      };
      const result = await Api.get(params);
      if (result) {
        if (result.data && result.structure) {
          result.structure.searchselect!.filter = true;
          this.fullOrgList = {
            data: result.data as Org[],
            structure: { ...result.structure, trusted_struct: true },
          };
        }
      }
    },
    async setInitialOrg(): Promise<number> {
      const userId = useAuthStore().currentUser.id_login;

      const orgInStorage = localStorage.getItem(`medSafeCompany-${userId}`);
      if (!orgInStorage) {
        return 0;
      }
      let savedOrg: Org | null = null;

      try {
        savedOrg = JSON.parse(orgInStorage) as Org;
      } catch (error) {
        useMainStore().showMessage('Не удается загрузить организацию');
        return 1;
      }

      if (!savedOrg) {
        await this.getFullOrgList();
        const currentOrg = this.fullOrgList.data.length === 1
          ? this.fullOrgList.data[0]
          : null;
        if (currentOrg) {
          this.setCurrentOrg(currentOrg);
        }
        return currentOrg?.id_org || 2;
      }
      this.setCurrentOrg(savedOrg);
      return savedOrg.id_org;
    },
    setCurrentOrg(currentOrg: Org): void {
      const userId = useAuthStore().currentUser.id_login;
      if (currentOrg) {
        this.currentOrg = currentOrg;
        localStorage.setItem(`medSafeCompany-${userId}`, JSON.stringify(currentOrg));
        useTreeStore().$reset();
        useContractsStore().$reset();
        useResponsiblingsStore().$reset();
        usePatientsStore().$reset();
      }
    },
    async loadDoers() {
      const requestData = {
        ...this.constantParams,
        Name_event: 'GET:doers',
      };
      const result = await Api.get(requestData);
      if (result) {
        if (result.data && result.structure) {
          const { data } = result;
          this.doers = data as Doer[];
        }
      }
    },
    async loadContractTypes() {
      const requestData = {
        ...this.constantParams,
        Name_event: 'GET:contract_types',
      };
      const result = await Api.get(requestData);
      if (result.data && result.structure) {
        this.contractTypes = result.data as ContractType[];
      }
    },
    async loadOrgById(id: number) {
      if (!this.fullOrgList.data?.length) {
        await this.getFullOrgList();
      }
      const org = this.fullOrgList.data?.find((el) => el.id_org === id);
      return org;
    },
    // async getExistedOrgData(inn: string) {
    //   const params = {
    //     inn,
    //   };
    //   const result = await useMainStore().dispatchEvent('GET:fact_exist_org', params);
    //   if (result.data[0]?.inn) {
    //     return result.data[0];
    //   }
    //   return false;
    // },
  },
});
