import { TableStructure } from '~components/TableComponent/types';

export const contactsStructure: TableStructure = {
  title: 'Контакты и учетные записи',
  field_id: 'N',
  columns: [
    {
      name_column: 'fio',
      colname: 'ФИО',
      visible: true,
      pinned: true,
      content_type: 'default',
    },
    {
      name_column: 'contacts',
      colname: 'Контакты',
      visible: true,
      pinned: true,
      content_type: 'json_string',
      custom_component_name: 'PersonContacts',
    },
    {
      name_column: 'accounts',
      colname: 'Учетки',
      visible: true,
      pinned: true,
      content_type: 'json_string',
      custom_component_name: 'PersonAccounts',
    },
  ],
};
