export enum Modules {
  WEBMANAGERS = 'WEB_managers',
  CALCULATION = 'module_calculation',
  CONTACTS = 'module_contacts',
  MED_ADMIN = 'medAdmin',
  COVID = 'Covid',
  PATIENTS = 'module_patients',
}

export enum Sex {
  м = 1,
  ж = 2,
}

export enum CommonEmits {
  CLOSE = 'close',
  REFRESH = 'refresh',
  ACTION = 'action',
}

export enum BindingStatus {
  BINDED = 'binded',
  NOT_BINDED = 'not_binded',
}
