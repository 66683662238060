import { useOrgsStore } from '~store/orgs/orgsModule';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import { TreeItem } from '@/Tree/types';
import { ITable } from '@/components/TableComponent/types';
import { IDropParams } from './types';

export const useTreeStore = defineStore('tree', {
  state: () => ({
    constantParams: useMainStore().constantParams,
    treeData: {} as ITable<TreeItem>,
    currentItem: null as number | null,
  }),
  getters: {
    getRoot: (state) => state.treeData.data.find((item) => item.id_parent === '0'),
  },
  actions: {
    async loadTree() {
      const data = {
        ...this.constantParams,
        Name_event: 'GET:tree_v2',
        id_org: useOrgsStore().currentOrgId,
      };
      const result = await Api.get(data);
      if (result.data && result.structure) {
        this.treeData = {
          data: result.data as TreeItem[],
          structure: result.structure,
        };
      }
    },
    async loadTreeNode(id_tree: string) {
      const requestData = {
        ...this.constantParams,
        Name_event: 'GET:tree_v2',
        id_org: useOrgsStore().currentOrgId,
        id_tree,
      };
      const result = await Api.get(requestData);
      if (result.data && result.structure) {
        const data = result.data as TreeItem[];
        if (data && data.length) {
          this.treeData.data = this.treeData.data.concat(data as TreeItem[]);
          return { data, id_tree };
        }
      }
      return false;
    },
    async moveTreeItem(params: IDropParams) {
      const requestData = {
        ...this.constantParams,
        ...params,
        Name_event: !params.type_drop ? 'GET:tree_node_sub_v2' : 'GET:tree_node_move_v2',
      };
      if (params.type_drop) {
        return;
      }
      const result = await Api.get(requestData);
      if (result) {
        const { data } = result;
        if (data) {
          const nodeToChange = this.treeData.data
            .findIndex((item) => item.id_tree === params.id_tree_drop);
        }
      }
    },
    addTreeItem(item: TreeItem) {
      this.treeData.data.push(item);
    },
    setCurrentItem(item: number | null) {
      this.currentItem = item;
    },
  },
});
