import { NAME_APP } from '@/constants';
import { Modules } from '@/enums';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { deleteCookie, getCookie } from '~utils/cookies';
import { ConstantParams } from '../types';
import { useMainStore } from '../index';
import { useContractsStore } from '../contracts/contractsModule';
import { useOrgsStore } from '../orgs/orgsModule';
import { useResponsiblingsStore } from '../responsiblings/responsiblingsModule';
import { User, UserData } from './types';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    IMEI: '',
    user: {} as User,
  }),

  getters: {
    getIsAuth: (state) => state.isAuth,
    currentUser: (state) => state.user,
    currentUserId: (state) => state.user.id_login,
    userName: (state) => state.user.vrach_fio || '',
  },

  actions: {
    checkAuth() {
      const storedUserData: UserData = {
        id_login: Number(getCookie('id_login')) || 0,
        TK: getCookie('TK') || '',
      };
      if (storedUserData.id_login && storedUserData.TK) {
        return storedUserData;
      }
      return false;
    },
    async autenticate(): Promise<void> {
      if (!this.IMEI) {
        this.IMEI = getCookie('IMEI') || '';
      }
      const userData: UserData | boolean = this.checkAuth();
      if (!userData) {
        return;
      }
      const params = {
        ...userData,
        IMEI: this.IMEI,
        Name_event: 'GET:user_data',
        Name_app: NAME_APP,
      };
      const result = await Api.get(params);
      if (!result.errorType && result.data) {
        [this.user] = result.data as User[];

        const constantParams: ConstantParams = {
          IMEI: this.IMEI,
          Name_app: NAME_APP,
          TK: userData.TK,
          id_login: this.user.id_login,
          Name_module: Modules.WEBMANAGERS,
        };
        useMainStore().setConstantParams(constantParams);
        this.isAuth = true;
        await useOrgsStore().setInitialOrg();
      }
    },
    async logout(): Promise<void> {
      this.isAuth = false;
      this.user = {} as User;
      useMainStore().$reset();
      useOrgsStore().$reset();
      useContractsStore().$reset();
      useResponsiblingsStore().$reset();
      deleteCookie('id_login');
      deleteCookie('TK');
      deleteCookie('IMEI');
    },
  },
});
