import { TreeItem } from './types';

export default class Node {
  public data: TreeItem;

  public parent: Node | null = null;

  public children: Node[] = [];

  constructor(data: TreeItem) {
    this.data = data;
  }

  get _parent() {
    return this.parent;
  }

  set _parent(parent) {
    this.parent = parent;
  }
}
