import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "./App.vue?vue&type=style&index=0&id=914d435c&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QInnerLoading,QFooter,QToolbar,QSpace,QDialog,QCard,QCardSection,QBtn,QTabPanel});
