type FullNameObj = {
  lastname: string,
  firstname: string,
  middlename: string,
}

export const splitFullName = (fullName: string): FullNameObj => {
  let rest = [];
  const result: FullNameObj = {
    lastname: '',
    firstname: '',
    middlename: '',
  };
  [
    result.lastname = '',
    result.firstname = '',
    ...rest
  ] = fullName.split(' ');

  if (rest.length) {
    result.middlename = rest.join(' ');
  }
  return result;
};

export const getFullNameWithInitials = (fullName: string | undefined): string => {
  if (!fullName) {
    return '';
  }
  const fullNameObj = splitFullName(fullName);
  return `${fullNameObj.lastname} ${fullNameObj.firstname.substring(0, 1)}.${fullNameObj.middlename.substring(0, 1)}.`;
};

export const addZeros = (num: any): string => {
  if (typeof num !== 'number') {
    return num;
  }
  const resultStr = num.toString(10);
  if (resultStr.includes('.')) {
    const secondPart = resultStr.split('.')[1];
    if (secondPart.length < 2) {
      return resultStr.concat('0');
    }
    return resultStr;
  }
  return resultStr.concat('.00');
};
