import { ITable } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
import router from '@/router';
import { Modules } from '@/enums';
import { useOrgsStore } from '../orgs/orgsModule';
import { useContractsStore } from '../contracts/contractsModule';
import {
  Dates,
  IEmployee,
  IEmployeeList,
  IPatient,
  IPatientAddress,
  IDocFormType,
  IForeignDocField,
  IMOType,
  IContractInTree,
  IStaff,
  ITypeForGenerate,
  IDocTemplate,
  IPaymentType,
} from './types';
import { useTreeStore } from '../tree/treeModule';

type LastLoadParams = {
  event: string,
  additionalParams: Record<string, unknown> | null,
  mode: 0 | 1,
};

export const usePatientsStore = defineStore('patients', {
  state: () => ({
    constantParams: useMainStore().constantParams,
    currentTable: {} as ITable<IPatient | IStaff>,
    employeesLists: {} as ITable<IEmployeeList>,
    employee: {} as ITable<IEmployee>,
    dates: {
      date_from: '',
      date_till: '',
    } as Dates,
    currentPatient: {} as IPatient,
    currentStaff: {} as IStaff,
    currentPatientData: {
      patientAddress: {} as IPatientAddress,
    },
    foreignDocFields: [] as IForeignDocField[],
    tableMode: 0,
    MOTypes: [] as IMOType[],
    paymentTypes: [] as IPaymentType[],
    contractsInTree: [] as IContractInTree[],
    lastLoadParams: {} as LastLoadParams,
    typesForGenerate: [] as ITypeForGenerate[],
    tableLoading: false,
  }),
  getters: {

  },
  actions: {
    async getTableData(event = 'GET:patient_org_list', additionalParams: Record<string, unknown> | null = null, mode: 0 | 1 = 0) {
      this.tableLoading = true;
      console.log(this.tableLoading);
      this.setTableMode(mode);
      const params = {
        ...this.constantParams,
        Name_event: event,
        id_org: useOrgsStore().currentOrgId,
        ...additionalParams,
        delayed: true,
      };
      if (event === 'GET:patient_org_list') {
        Object.assign(params, ...[this.dates]);
      }
      this.lastLoadParams = {
        event,
        additionalParams,
        mode,
      };
      const result = await Api.get(params);

      if (result.data && result.structure) {
        result.structure.pagination = true;
        this.currentTable = result as unknown as ITable<IPatient | IStaff>;
        console.log(this.currentTable);
      }
      this.tableLoading = false;
    },
    async refreshTable() {
      const { event, additionalParams } = this.lastLoadParams;
      await this.getTableData(event, additionalParams);
    },
    async loadEmployeeLists() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:employee_list',
        id_org: useOrgsStore().currentOrgId,
      };
      const result = await Api.get(params);
      if (result.data && result.structure) {
        this.employeesLists = {
          data: result.data as IEmployeeList[],
          structure: result.structure,
        };
      }
    },
    async loadEmployee(customParam: Record<string, unknown>) {
      const params = {
        ...customParam,
        ...this.constantParams,
        Name_event: 'GET:employee',
        id_org: useOrgsStore().currentOrgId,
      };
      const result = await Api.get(params);
      if (result.data && result.structure) {
        this.employee = {
          data: result.data as IEmployee[],
          structure: result.structure,
        };
      }
    },
    async loadPatientsByContract() {
      router.push({ name: 'Structure' });
      await this.getTableData('GET:patient_org_list', { id_contract: useContractsStore().currentContractId });
    },
    setDates(dates: Dates) {
      this.dates = dates;
    },
    setTableMode(mode: number) {
      this.tableMode = mode;
    },
    async loadPatientAddress() {
      const params = {
        ...this.constantParams,
        id_patient: this.currentPatient.id_patient,
        Name_event: 'GET:patient_address',
        Name_module: 'module_patients',
      };
      const result = await Api.get(params);
      if (result && result.data?.length) {
        try {
          const addressObject = result.data[0] as { patient_adress: string };
          const addresses = JSON
            .parse(addressObject.patient_adress);
          if (addresses) {
            [this.currentPatientData.patientAddress] = addresses;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loadPatientData(patient: IPatient) {
      this.currentPatient = patient;
      await this.loadPatientAddress();
    },
    async loadDocFormTypes() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:doc_form_types_a',
        Name_module: 'module_patients',
      };
      const result = await Api.get(params);
      if (result) {
        const foreignDocFields: IForeignDocField[] = [];
        const docFormTypes = result.data as IDocFormType[];
        docFormTypes.forEach((item) => {
          const existedDocType = foreignDocFields.find((el) => el.id === item.id);
          if (item.field_name) {
            if (existedDocType) {
              existedDocType.fields.push(item);
            } else {
              foreignDocFields.push({
                id: item.id,
                name: item.doc_name,
                fields: [item],
              });
            }
          }
        });
        this.foreignDocFields = foreignDocFields as IForeignDocField[];
      }
    },
    async getMOTypes() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:type_MO_spr',
        Name_module: Modules.PATIENTS,
      };
      const result = await Api.get(params);
      if (result) {
        this.MOTypes = result.data as IMOType[];
      }
    },
    async getPaymentTypes() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:spr_payment_type',
        Name_module: Modules.PATIENTS,
      };
      const result = await Api.get(params);
      if (result) {
        this.paymentTypes = result.data as IPaymentType[];
      }
    },
    async getContractsInTree() {
      const params = {
        ...this.constantParams,
        Name_event: 'GET:contracts_in_tree',
        id_tree: useTreeStore().currentItem,
        show_all: 1,
      };
      const result = await Api.get(params);
      if (result) {
        this.contractsInTree = result.data as IContractInTree[];
      }
    },
    setCurrentStaff(staff: IStaff) {
      this.currentStaff = staff;
    },
    async loadDocTypes() {
      const result = await useMainStore().dispatchEvent('GET:doc_gen_types.module_patients') as ITable;
      if (result) {
        this.typesForGenerate = result.data.map((item) => {
          let templates: IDocTemplate[] = [];
          try {
            templates = JSON.parse(item.templates) as IDocTemplate[];
          } catch (e) {
            console.log(e);
          }
          return {
            ...item,
            templates,
          };
        }) as ITypeForGenerate[];
      }
    },
    async employeeToTree(list: IEmployeeList) {
      const params = {
        ...this.constantParams,
        id_contract: useContractsStore().currentContractId,
        Name_module: Modules.CALCULATION,
        Name_event: 'POST:employee_to_tree',
        json_param: JSON.stringify({
          id_list: list.id_list,
          id_parent: '',
          override: 1,
        }),
      };
      const result = await Api.post(params);
      if (result.data) {
        await useTreeStore().loadTree();
      }
    },
  },
});
