import {
  Action, ITable,
} from '~components/TableComponent/types';
import { useMainStore } from '@/store';
import { IResponse } from '@/types';
import { ActionTypes } from './actionTypes';
import { actions } from './actions';

export const actionHandler = async (action: Action, params: Record<string, unknown> | null = null):
  Promise<boolean | void | unknown[] | ITable | IResponse> => {
  if (params && typeof action.customParam === 'object') {
    const { customParam } = action;
    // eslint-disable-next-line no-param-reassign
    action.customParam = {
      ...customParam,
      ...params,
    };
  }
  if (action.confirmation) {
    const confirmAction: Action = {
      type: 'modal',
      name: 'ConfirmModal',
      customParam: {
        content: action.confirmationText,
        nextAction: {
          ...action,
          confirmation: false,
        },
      },
    };
    actionHandler(confirmAction);
    return;
  }
  if (action.type === 'modal') {
    useMainStore().openModal(action.name, action.customParam);
    return;
  }
  if (action.type === 'event') {
    let eventParams;
    if (typeof action.customParam === 'string') {
      eventParams = JSON.parse(action.customParam);
    } else {
      eventParams = action.customParam;
    }
    const result = await useMainStore().dispatchEvent(action.name, eventParams);
    // eslint-disable-next-line consistent-return
    return result;
  }
  if (action.type === 'custom') {
    const actionId = action.name as ActionTypes;
    const customAction = actions[actionId];
    if (!customAction) {
      throw new Error(`Action ${action.name} not found!`);
    }
    let actionParams: Record<string, unknown>;
    if (!action.customParam) {
      customAction(null);
    }
    if (action.customParam) {
      try {
        if (typeof action.customParam === 'string') {
          actionParams = JSON.parse(action.customParam);
        } else {
          actionParams = action.customParam;
        }
        customAction(actionParams);
      } catch (error) {
        console.log(error);
        customAction(action.customParam);
      }
    }
  }
  if (action.type === 'menu') {
    // console.log(action);
  }
  if (action.type === 'callback') {
    if (!action.callback) {
      throw new Error('Callback not passed!');
    }
    action.callback(action.customParam);
  }
};
